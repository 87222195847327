import './proyectsView.css'
import { proyect1, proyect2, proyect3, proyect4, proyect5, proyect6, proyect7, proyect8, proyect9, proyect10, proyect11, proyect12, proyect13, proyect14, proyect15, proyect16, proyect17, proyect18, proyect19, proyect20, proyect21, proyect22, proyect23, proyect24, proyect25, proyect26, proyect27, proyect28, proyect29, proyect30 } from '../../assets'
export const ProyectsView = () => {
    return (
        <div className='ProyectsView'>
            <div>
                <img src={proyect2} alt='no funciona' />
                <img src={proyect1} alt='no funciona' />
                <img src={proyect3} alt='no funciona' />
                <img src={proyect4} alt='no funciona' />
                <img src={proyect5} alt='no funciona' />
                <img src={proyect6} alt='no funciona' />
                <img src={proyect7} alt='no funciona' />
                <img src={proyect8} alt='no funciona' />
                <img src={proyect9} alt='no funciona' />
                <img src={proyect10} alt='no funciona' />
                <img src={proyect11} alt='no funciona' />
                <img src={proyect12} alt='no funciona' />
                <img src={proyect13} alt='no funciona' />
                <img src={proyect14} alt='no funciona' />
                <img src={proyect15} alt='no funciona' />
            </div>
            <div>
                <img src={proyect16} alt='no funciona' />
                <img src={proyect17} alt='no funciona' />
                <img src={proyect18} alt='no funciona' />
                <img src={proyect19} alt='no funciona' />
                <img src={proyect20} alt='no funciona' />
                <img src={proyect21} alt='no funciona' />
                <img src={proyect22} alt='no funciona' />
                <img src={proyect23} alt='no funciona' />
                <img src={proyect24} alt='no funciona' />
                <img src={proyect25} alt='no funciona' />
                <img src={proyect26} alt='no funciona' />
                <img src={proyect27} alt='no funciona' />
                <img src={proyect28} alt='no funciona' />
                <img src={proyect29} alt='no funciona' />
                <img src={proyect30} alt='no funciona' />
            </div>
        </div>
    )
}
