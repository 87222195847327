import { useState } from 'react';
import { CircularProgress, TextField } from '@mui/material';
import { globalApi } from '../../api/globalApi';
import { useForm } from '../../hooks/customForm';
import Swal from 'sweetalert2';
import './contactUsForm.css'
const formData = {
    nombre: '',
    email: '',
    asunto: '',
    mensaje: ''
}
const validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
const formDataValid = {
    nombre: [(value) => value.length >= 3, 'Requerido'],
    email: [(value) => validEmail.test(value), 'Requerido'],
    asunto: [(value) => value.length >= 5, 'Requerido'],
    mensaje: [(value) => value.length >= 15, 'Requerido'],
}
export const ContactUsForm = () => {
    const [isLoading, setisLoading] = useState(false)
    const [setSubmitted, setsetSubmitted] = useState(false)
    const { formState, nombre, email, asunto, mensaje, nombreValid, emailValid, asuntoValid, mensajeValid, onResetForm, isFormValid, onInputChange } = useForm(formData, formDataValid)
    const onSubmit = async (e) => {
        e.preventDefault();
        setsetSubmitted(true);
        if (!isFormValid) return
        try {
            setisLoading(true)
            await globalApi.post('/webPage/farogama/sendEmail', formState)
            Swal.fire({
                title: 'Gracias por tu interes.',
                text: 'Mensaje enviado.',
                icon: 'success',
                iconColor: '#a5e7e7',
                confirmButtonColor: '#213059',
                confirmButtonText: 'OK',
            })
            onResetForm()
            setsetSubmitted(false);
        } catch (error) {
            Swal.fire({
                title: 'Error al enviar el mensaje',
                text: 'Intenta de nuevo',
                icon: 'error',
                confirmButtonColor: '#213059',
                confirmButtonText: 'OK',
            })
            console.log(error)
        }
        setisLoading(false)
    }
    return (
        <div className='contactUsForm'>
            <div className='contactus'>
                <form className='contactusText' onSubmit={onSubmit}>
                    <h3>CONTÁCTANOS</h3>
                    <div className='contactusTwoText'>
                        <TextField id="standard-basic"
                            InputLabelProps={{
                                style: { color: '#213059', fontFamily: 'DIN Next Rounded LT Pro Bold' },
                            }}
                            label="Nombre:"
                            name='nombre'
                            variant="standard" fullWidth
                            value={nombre}
                            onChange={onInputChange}
                            error={!!nombreValid && setSubmitted}
                            helperText={nombreValid}
                        />
                        <span />
                        <TextField id="standard-basic"
                            InputLabelProps={{
                                style: { color: '#213059', fontFamily: 'DIN Next Rounded LT Pro Bold' },
                            }}
                            label="Email:"
                            name='email'
                            value={email}
                            helperText={emailValid}
                            onChange={onInputChange}
                            variant="standard" fullWidth
                            error={!!emailValid && setSubmitted}
                        />
                    </div>
                    <TextField id="standard-basic"
                        InputLabelProps={{
                            style: { color: '#213059', fontFamily: 'DIN Next Rounded LT Pro Bold' },
                        }}
                        label="Asunto:"
                        name='asunto'
                        variant="standard"
                        value={asunto}
                        onChange={onInputChange}
                        error={!!asuntoValid && setSubmitted}
                        helperText={asuntoValid}
                    />
                    <TextField id="standard-basic"
                        InputLabelProps={{
                            style: { color: '#213059', fontFamily: 'DIN Next Rounded LT Pro Bold' },
                        }}
                        label="Escribe tu mensaje aqui:"
                        name='mensaje'
                        multiline
                        rows={6} variant="standard"
                        value={mensaje}
                        onChange={onInputChange}
                        error={!!mensajeValid && setSubmitted}
                        helperText={mensajeValid}
                    />
                    <div className='button'>
                        {
                            (isLoading)
                                ? <CircularProgress />
                                : <button type="submit">Enviar</button>
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}
