
import { HomeView, UsView, ServicesView, ProyectsView, CatalogueView, ContactUsView, PressView } from '../views';
import { Footer, NavbarTest } from '../components';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
function App() {
  return (
    <Router>
      <div className="App">
        <NavbarTest />
        <body>
          <Routes>
            <Route path="/" element={<HomeView />} />
            <Route path="usView" element={<UsView />} />
            <Route path="servicesView" element={<ServicesView />} />
            <Route path="proyectsView" element={<ProyectsView />} />
            <Route path="catalogueView" element={<CatalogueView />} />
            <Route path="contactUsView" element={<ContactUsView />} />
            <Route path="*" element={<Navigate replace to="/" />} />
            <Route path="catalogodiadelasmadres2024" element={<PressView url="https://online.fliphtml5.com/pbfyk/rkst/" />} />
            <Route path="catalogofindeaño" element={<PressView url="https://online.flippingbook.com/view/426512321" />} />
          </Routes>
        </body>
        <Footer />
      </div >
    </Router>
  );
}

export default App;
