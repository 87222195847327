import './usView.css'
export const UsView = () => {
  return (
    <div className='usView'>
      <h2 className='usView__title'>¿QUIÉNES SOMOS?</h2>
      <p className='usView__p'>
        Somos una empresa con más de <b>15 años de experiencia</b> haciendo realidad tus proyectos de Marketing.  Nuestro equipo cuenta con la experiencia necesaria para elaborar <b>estrategias de 360º</b> basadas en tus objetivos de negocio.<br />
        En <b>FAROGAMA</b> contamos con diseñadores experimentados en la creación de cualquier idea para publicitar tu marca e implementarla en el mundo físico (Stands, flyers, mercancía promocional, etc.) o digital (banners, página web, API, desarrollo de app personalizadas etc.) a los mejores precios del mercado. <br />Entregamos resultados <b> creativos, personalizados y respetando los tiempos de entrega.  </b></p>
    </div>
  )
}
