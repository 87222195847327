import Iframe from 'react-iframe'
import { ContactUsForm } from '../../components'
import { contactUs } from '../../assets'
import './contactUsView.css'
export const ContactUsView = () => {
    return (
        <div className='contactUsView'>
            <ContactUsForm />
            <img src={contactUs} alt='contactUs' />
            <Iframe url='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d940.8025312806681!2d-99.16499567077317!3d19.403325064115933!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff1465b2969b%3A0x1a418e290431aee9!2sC.%20Baj%C3%ADo%20234%2C%20Roma%20Sur%2C%20Cuauht%C3%A9moc%2C%2006760%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses!2smx!4v1678145544397!5m2!1ses!2smx' />
        </div>
    )
}
