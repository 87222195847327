import { Link } from "react-router-dom";
import { logo } from '../../assets'
import './navbarTest.css'
export const NavbarTest = () => {
    return (
        <header>
            <img src={logo} alt='logo farogama' className="bigLogo" />
            <nav className="navbar">
                <Link to='/' className="navLink">
                    <img src={logo} alt='logo' className="logo" />
                </Link>
                <div className="navbar-container container">
                    <input type="checkbox" name="" id="" />
                    <div className="hamburger-lines">
                        <span className="line line1"></span>
                        <span className="line line2"></span>
                        <span className="line line3"></span>
                    </div>
                    <ul className="menu-items">
                        <li>
                            <Link to='/' className="navLink">
                                <a href="">Inicio</a>
                            </Link>
                        </li>
                        <li>
                            <Link to='/usView' className="navLink">
                                <a href="" className='navLink'>Nosotros</a>
                            </Link>
                        </li>
                        <li>
                            <Link to='/servicesView' className="navLink">
                                <a href="" className='navLink'>Servicios</a>
                            </Link>
                        </li>
                        
                        <li>
                            <Link to='/catalogueView' className="navLink">
                                <a href="" className='navLink'>Catálogos</a>
                            </Link>
                        </li>
                        <li>
                            <Link to='/contactUsView' className="navLink">
                                <a href="" className='navLink'>Contáctanos</a>
                            </Link>
                        </li>

                    </ul>
                </div>
            </nav>
        </header>
    )
}
