import { useState, useEffect, useMemo } from 'react';
export const useForm = (initialForm = {}, formValidations = {}) => {
    const [direccionField, setdireccionField] = useState([])
    const [formState, setFormState] = useState(initialForm);
    const [formValidation, setformValidation] = useState({});

    const createValidators = () => {
        const formCheckedValues = {};
        for (const formField of Object.keys(formValidations)) {
            const [fn, errorMessage] = formValidations[formField]
            formCheckedValues[`${formField}Valid`] = fn(formState[formField]) ? null : errorMessage;
        }
        setformValidation(formCheckedValues);
    }
    useEffect(() => {
        createValidators();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState]);
    const isFormValid = useMemo(() => {
        for (const formValue of Object.keys(formValidation)) {
            if (formValidation[formValue] !== null) return false;
        }
        return true
    }, [formValidation])

    const onInputChange = ({ target }) => {
        const { name, value } = target;
        setFormState({
            ...formState,
            [name]: value
        });
    }
    const onSearchCp = async ({ target }) => {
        const { name, value } = target;
        setFormState({
            ...formState,
            [name]: value
        });
        if (value.length === 5) {
            try {
                /*  const { data } = await globalApiCp.get(`/${value}?token=384745ce-f214-48fa-95d1-af80b88f125e`);
                 const response = await globalApi.get(`/webPage/searchPriceCp?cp=${value}`);
                 if (response.data.PRECIODOMICILIO) {
                     var concatField = []
                     data.forEach(function (direction) {
                         concatField.push(direction.response)
                     })
                     setdireccionField(concatField)
                     dispatch(addPriceService({ priceService: response.data.PRECIODOMICILIO }))
                     setFormState({
                         ...formState,
                         [name]: value
                     });
                 } else {
                     throw TypeError("Cp not valid")
                 } */
            } catch (error) {
                setdireccionField([])
                /* Swal.fire({
                    title: 'Codigo postal no valido',
                    icon: "warning",
                    confirmButtonText: "Continuar",
                }) */
            }
        }
    }

    const onResetForm = () => {
        setFormState(initialForm);
    }

    const onInputChangeNum = (value, name) => {
        setFormState({
            ...formState,
            [name]: value
        });

    }
    const onInputChangeTime = (value, name, isDate = false) => {
        let valor;
        (isDate) ? valor = value.$d.toISOString().slice(0, 10) : valor = value.$d
        setFormState({
            ...formState,
            [name]: `${valor}`,
        });

    }

    return {
        ...formState,
        formState,
        onSearchCp,
        direccionField,
        onInputChange,
        onResetForm,
        ...formValidation,
        isFormValid,
        onInputChangeNum,
        onInputChangeTime
    }
}