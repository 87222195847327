export const logo = require('./logos/logoFinal.png')
export const logoSmall=require('./logos/logoSmall.png')
export const ourHistory = require('./productos/ourHistory.png')
export const usViewImg = require('./productos/usSection.png')
export const contactUs=require('./services/contactUs.jpg')
export const product1 = require('./productos/1.1.png')
export const product2 = require('./productos/2.2.png')
export const product3 = require('./productos/3.png')
export const product4 = require('./productos/4.4.png')
export const product5 = require('./productos/5.5.png')
export const product6 = require('./productos/6.6.png')
export const product7 = require('./productos/7.7.png')
export const product8 = require('./productos/8.8.png')
export const product9 = require('./productos/9.9.png')
export const product10 = require('./productos/10.10.png')
export const product11 = require('./productos/12.12.png')
export const product12 = require('./productos/13.13.png')
export const service1 = require('./services/service1.png')
export const service2 = require('./services/service2.png')
export const service3 = require('./services/service3.png')
export const service4 = require('./productos/15.png')
export const service5 = require('./services/service4.png')
export const service6 = require('./productos/14.png')
export const service7 = require('./services/service5.png')
export const service8 = require('./productos/9.9.png')
export const service9 = require('./productos/5.5.png')
export const service10 = require('./services/service6.png')
export const service11 = require('./services/service7.png')
export const service12 = require('./services/bigService.png')
export const proyect1 = require('./proyects/1.png')
export const proyect2 = require('./proyects/2.png')
export const proyect3 = require('./proyects/3.png')
export const proyect4 = require('./proyects/4.png')
export const proyect5 = require('./proyects/5.png')
export const proyect6 = require('./proyects/6.png')
export const proyect7 = require('./proyects/7.png')
export const proyect8 = require('./proyects/8.png')
export const proyect9 = require('./proyects/9.png')
export const proyect10 = require('./proyects/10.png')
export const proyect11 = require('./proyects/11.png')
export const proyect12 = require('./proyects/12.png')
export const proyect13 = require('./proyects/13.png')
export const proyect14 = require('./proyects/14.png')
export const proyect15 = require('./proyects/15.png')
export const proyect16 = require('./proyects/16.png')
export const proyect17 = require('./proyects/17.png')
export const proyect18 = require('./proyects/18.png')
export const proyect19 = require('./proyects/19.png')
export const proyect20 = require('./proyects/20.png')
export const proyect21 = require('./proyects/21.png')
export const proyect22 = require('./proyects/22.png')
export const proyect23 = require('./proyects/23.png')
export const proyect24 = require('./proyects/24.png')
export const proyect25 = require('./proyects/25.png')
export const proyect26 = require('./proyects/26.png')
export const proyect27 = require('./proyects/27.png')
export const proyect28 = require('./proyects/28.png')
export const proyect29 = require('./proyects/29.png')
export const proyect30 = require('./proyects/30.png')
export const catalogue2=require('./catalogue/Reconocimientos_compressed.pdf')
export const catalogue3=require('./catalogue/USB_compressed.pdf')
export const catalogue4=require('./catalogue/FechasEspeciales_compressed.pdf')
export const catalogue5=require('./catalogue/Exclusive_compressed.pdf')
export const catalogue6=require('./catalogue/Textil_compressed.pdf')
export const catalogue7=require('./catalogue/FAROGAMA-CATALOGO.pdf')


