import Iframe from 'react-iframe';
import { product1, product10, product11, product12, product2, product3, product4, product5, product6, product7, product8, product9, service1, service2, service3 } from '../../assets';
import './homeView.css'
import { ContactUsForm, } from '../../components';
export const HomeView = () => {
  return (
    <div className='HomeView'>
      <div className='title'>
        <h2>TU MARCA DONDE QUIERAS Y COMO QUIERAS</h2>
      </div>
      <div className='products'>
        <img src={product1} alt='productos' />
        <img src={product2} alt='productos' />
        <img src={product3} alt='productos' />
        <img src={product4} alt='productos' />
        <img src={product5} alt='productos' />
        <img src={product6} alt='productos' />
        <img src={product7} alt='productos' />
        <img src={product8} alt='productos' />
        <img src={product9} alt='productos' />
        <img src={product10} alt='productos' />
        <img src={product11} alt='productos' />
        <img src={product12} alt='productos' />
      </div>
      <ContactUsForm />
      <div className='map'>
        <Iframe
          url='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d940.8025312806681!2d-99.16499567077317!3d19.403325064115933!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff1465b2969b%3A0x1a418e290431aee9!2sC.%20Baj%C3%ADo%20234%2C%20Roma%20Sur%2C%20Cuauht%C3%A9moc%2C%2006760%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses!2smx!4v1678145544397!5m2!1ses!2smx'
        />
      </div>
    </div>
  )
}
