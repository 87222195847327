import './service.css'
import { Link } from "react-router-dom";
export const Service = ({ img, title, desc, button = false, linkButton = '', secondTitle }) => {
    return (
        <div className='service'>
            <div className='colorGrey'>
                <div>
                    <img src={img} alt='servicios' />
                </div>
                <div className='serviceText'>
                    <h4 className='serviceText__title'>{title}<br /> {secondTitle} </h4>
                    <p>{desc}</p>
                    {
                        (button)
                            ? <a target="_blank" rel="noopener noreferrer" href={linkButton} className='buttonBlue'>VER CATÁLOGO</a>
                            : <></>
                    }
                </div>
            </div>
        </div>
    )
}
export const ServiceBlue = ({ img, title, desc, button = false, linkButton = '', secondTitle }) => {
    return (
        <div className='service'>
            <div className='color'>
                <div>
                    <img src={img} alt='servicios' />
                </div>
                <div className='serviceText'>
                    <h4>{title}<br />{secondTitle} </h4>
                    <p>{desc}</p>
                    {
                        (button)
                            ? <a target="_blank" rel="noopener noreferrer" href={linkButton} className='buttonGrey'>VER CATÁLOGO</a>
                            : <></>
                    }
                </div>
            </div>
        </div>
    )
}
export const NewService = ({ img, title, desc, button = false, linkButton = '', secondTitle, link }) => {
    return (
        <div className='newService'>
            <div className='color'>
                <div>
                    <img className='serviceImg' src={img} alt='servicios' />
                </div>
                <div className='serviceText'>
                    <h4>{title}<br /> {secondTitle}</h4>
                    <p>{desc}  <Link to='/catalogueView' className='serviceText__link'>{link}</Link></p>
                    {
                        (button)
                            ? <a target="_blank" rel="noopener noreferrer" href={linkButton} className='buttonGrey'>VER CATÁLOGO</a>
                            : <></>
                    }
                </div>
            </div>
        </div>
    )
}