import { service12, service4, service5, service6, service7, service8, service9 } from '../../assets'
import { Service, ServiceBlue, NewService } from '../../components'
import './servicesView.css'
export const ServicesView = () => {
    return (
        <><NewService img={service12} title='STANDS' desc='Desarrollamos desde cero stands/pabellones/módulos/ escenarios/ Photopps desde los más sencillos hasta producciones complejas, consulta nuestros trabajos más recientes en' link=' Catálogos.' />
            <div className='servicesView'>
                <Service img={service4} title='TEXTILES' desc='Gorras - Playeras - Playeras de campaña - Chamarras - Chalecos - Camisas - Delantales - Sudaderas - Bandas edecanes - y mucho más..' />
                <ServiceBlue img={service5} title='PROMOCIONALES' desc='Salud - Cuidado Personal - Covid - Herramientas - Oficina - Plumas - Libretas y Agendas - Tiempo Libre - Niños - Regreso a Clase - Mujer - Hombre - Escritura - Hogar y mucho más..' />
                <Service img={service6} title='BRANDING' desc='Serigrafía - Vinil textil - Sandblast - Bordado - Impresión Digital - Sublimado - y mucho más...' />
                <Service img={service7} title='DISEÑO' desc='Diseño Gráfico e Industrial - flayers - promocionales - Banners - Logotipos - Marcas - Web - Digital - Cuadernos - Etiquetas - Folder - Imagen Corporativa y mucho más...' />
                <ServiceBlue img={service8} title='TECNOLOGÍA' desc='Baterias - Power banks - USB - Bocinas - Audífonos - Calculadoras - Productos Apple y mucho más...' />
                <Service img={service9} title='IMPRESIONES' desc='Flayers - Volantes - Banners - Lonas - Mantas - Roll Up - Cuadernos - Tarjetas de presentación - Hojas - Folders - Tripticos - Imanes - Postit - Tarjetas de citas - Minibanners - Etiquetas y mucho más...' />
            </div></>
    )
}
