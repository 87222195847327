import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#213059',
        },
        secondary: {
            main: '#a5e7e7',
        },
    },
    overrides: {
        root: {
            "& .MuiFormLabel-root": {
                color: "red"
            }
        }
    },
});